import {
  ComboBox,
  IComboBoxOption,
  Label,
  Pivot, PivotItem, Separator,
  Stack,
  Sticky,
  StickyPositionType
} from "@fluentui/react";
import { ActionButton, DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { useJsApiLoader } from "@react-google-maps/api";
import {
  IAddress,
  IEstablishment,
  IImpact
} from "@solarforschools/sfs-core/dist/school/types";
import _ from "lodash";
import moment from "moment";
import "office-ui-fabric-react/dist/css/fabric.css";
import { useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import { Provider, useDispatch, useSelector } from "react-redux";
import slugify from "slugify";
import store, { RootState } from "../../../store";
import {
  createUpdateSchool,
  deleteImage,
  unlinkProjectFolder,
  uploadImage
} from "../../../store/admin/school/action";
import { ISystemState } from "../../../store/admin/system/reducer";
import { awsUrlHost } from "../../../store/config";
import DynamicForm from "../../dynamic-form/DynamicForm";
import { imagePlaceholder } from "../../learning/Content/config";
import FileUpload from "../../learning/Content/formElements/FileUpload";
import { comboBoxStyles, googleMapsApiKey, Regions } from "../common/common";
import { ConfirmDialog } from "../common/ConfirmDialog";
import ModalDialog from "../common/ModalDialog";
import { onRenderPivoteItemLink } from "../common/PivotItemRenderLink";
import { mapLibs } from "./common";
import {
  addressFileds,
  displayDataFields,
  getGeneralFields,
  getMetaFields,
  getTabErrors,
  liveDataFields,
  locationFields,
  metaData,
  schoolTabs,
  validateSchool
} from "./helper";
import MapSearch from "./MapSearch";
import SchoolSystems from "./SchoolSystems";
import SchoolTasks from "./SchoolTasks";
import openProjectFolderModal from "../common/SelectProjectfolderDialog";
import { toast } from 'react-toastify';
import { isLoading } from "../../../store/admin/action";
import { registerIcons } from '@fluentui/react/lib/Styling';
import OpenCreateProjectFolderModal from "../common/CreateProjectfolderDialog";
import { getListItemAPi } from "../../../store/client/projectFolder";
import { IProjectFolder } from "@solarforschools/sfs-core";
import { IProjectFolderLink } from "@solarforschools/sfs-core/dist/projectFolder/types";
import { getOpportunityApi } from "../../../store/client/opportunity";
import { OpenOpportunityModal } from "../opportunity/AddOpportunity";
import { initOpportunity } from "../opportunity/helper";
import MetaSelection from "./MetaSelection";
import { projectNameChange } from "../project/helper";
import { ISchool } from "../../../store/admin/school/reducer";
import { AddPipedriveLead, OpenPipedriveLeadModal } from "../PipedriveLead/AddLead";
registerIcons({
  icons: {
    'folder-link': <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M18.25 14.5a3.75 3.75 0 0 1 .202 7.495l-.199.005-1 .005a.75.75 0 0 1-.108-1.493l.102-.007 1.003-.005a2.25 2.25 0 0 0 .154-4.495L18.25 16h-1a.75.75 0 0 1-.102-1.493l.102-.007h1Zm-4.5 0a.75.75 0 0 1 .102 1.493L13.75 16h-1a2.25 2.25 0 0 0-.154 4.495l.154.005h1a.75.75 0 0 1 .102 1.493L13.75 22h-1a3.75 3.75 0 0 1-.2-7.495l.2-.005h1Zm6-8a2.25 2.25 0 0 1 2.229 1.938l.016.158.005.154v6.585a4.742 4.742 0 0 0-3.75-1.835h-5.5a4.75 4.75 0 0 0-4.417 6.501L4.25 20a2.25 2.25 0 0 1-2.245-2.096L2 17.75v-7.251l6.207.001.196-.009a2.25 2.25 0 0 0 1.088-.393l.156-.12L13.821 6.5h5.929Zm-1.5 11a.75.75 0 0 1 .102 1.493L18.25 19h-5.5a.75.75 0 0 1-.102-1.493l.102-.007h5.5ZM8.207 4c.46 0 .908.141 1.284.402l.156.12 2.103 1.751-3.063 2.553-.085.061a.75.75 0 0 1-.29.106L8.206 9 2 8.999V6.25a2.25 2.25 0 0 1 2.096-2.245L4.25 4h3.957Z" fill="rgb(36, 63, 97)" /></svg>,
    'folder-add': <svg width="24" height="24" fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 11a5.5 5.5 0 1 1 0 11 5.5 5.5 0 0 1 0-11Zm0 2-.09.007a.5.5 0 0 0-.402.402L17 13.5V16L14.498 16l-.09.008a.5.5 0 0 0-.402.402l-.008.09.008.09a.5.5 0 0 0 .402.402l.09.008H17v2.503l.008.09a.5.5 0 0 0 .402.402l.09.008.09-.008a.5.5 0 0 0 .402-.402l.008-.09V17l2.504.001.09-.008a.5.5 0 0 0 .402-.402l.008-.09-.008-.09a.5.5 0 0 0-.403-.402l-.09-.008H18v-2.5l-.008-.09a.5.5 0 0 0-.402-.403L17.5 13Zm2.25-6.5a2.25 2.25 0 0 1 2.229 1.938l.016.158.005.154v3.06A6.5 6.5 0 0 0 12.023 20H4.25a2.25 2.25 0 0 1-2.245-2.096L2 17.75v-7.251l6.207.001.196-.009a2.25 2.25 0 0 0 1.088-.393l.156-.12L13.821 6.5h5.929ZM8.207 4c.46 0 .908.141 1.284.402l.156.12 2.103 1.751-3.063 2.553-.085.061a.75.75 0 0 1-.29.106L8.206 9 2 8.999V6.25a2.25 2.25 0 0 1 2.096-2.245L4.25 4h3.957Z" fill="rgb(36, 63, 97)" /></svg>
  },
});
export const openEditSchoolModal = (props: EditSchoolProps) => {
  return new Promise<IEstablishment | undefined>((resolve, reject) => {
    const mountTarget = document.createElement('div')
    document.body.appendChild(mountTarget)
    const callback = (school?: IEstablishment) => {
      resolve(school);
      ReactDOM.unmountComponentAtNode(mountTarget)
      mountTarget.remove()
    }
    ReactDOM.render(
      <Provider store={store}>
        <ModalDialog
          isModalOpen={true}
          title="Add Edit School"
          onDismiss={() => callback(undefined)}
        >
          <EditSchoolDialog
            {...props}
            onSave={school => callback(school)}
            onCancel={() => callback(undefined)}
          />
        </ModalDialog>
      </Provider>,
      mountTarget
    )
  })
}

const EditSchoolDialog = ({ school, onCancel, onSave, onFetchSystem }: EditSchoolProps) => {
  const dispatch = useDispatch()

  const { systems }: ISystemState = useSelector<RootState, ISystemState>((state) => state.web.system);
  const { services, services2, status } = useSelector<RootState, ISchool>(
    (state) => state.web.school
  );

  const setImpact = (school: IEstablishment) => {
    let impact = { ...school.impact }
    if (!impact) impact = { installed: null, peakPower: 0, panels: 0, images: [] };
    if (!impact.installed || !impact.peakPower || !impact.panels) {

      const installedDates: number[] = systems.map((s) => new Date(moment(s.installDate).format('YYYY-MM-DD')).getTime());
      const date = installedDates.length && Math.min(...installedDates)
      impact.installed = impact.installed || (date && new Date(date)) || null;
      impact.peakPower = impact.peakPower || systems.map((item) => item.peakPower || 0).reduce((prev, next) => prev! + next!, 0);
      impact.panels = impact.panels || systems.map((item) => item?.panels! || 0).reduce((prev, next) => prev + next, 0);
    }
    return { ...impact as IImpact }
  }
  const [selectedSchool, setSelectedSchool] = useState<IEstablishment>(_.cloneDeep({
    ...school,
    displaySettings: {
      ...school.displaySettings, quote: {
        ...school.displaySettings?.quote,
        display: school.displaySettings?.quote?.display && school.displaySettings?.quote?.display.toString() !== "false" ? true : false
      }
    },
    meta: { ...school.meta, isRegNotificationEnable: school.meta?.isRegNotificationEnable === false ? false : true },
    impact: setImpact(school)
  }));
  const [imageUrl, setImageUrl] = useState<string>(imagePlaceholder);

  const [errors, setErrors] = useState<any>({});
  const [tabErrors, setTabErrors] = useState({ ...schoolTabs });
  const generalFields = useMemo(() => getGeneralFields({ school }), [selectedSchool]);
  const metaFields = useMemo(() => getMetaFields({ school, status }), [selectedSchool, status]);



  const handleOnChangeInput = (key: string, value: any) => {
    let school: IEstablishment = _.cloneDeep(selectedSchool);
    if (errors[key]) {
      const err = { ...errors };
      delete err[key];
      const tabErrors = getTabErrors(err, school);
      setErrors(err);
      setTabErrors(tabErrors);
    }
    switch (key) {
      case 'name':
        if (!school._id) {
          school.slug = slugify(`${value}-${school.urn}`, {
            replacement: '-',
            remove: /[*+~.()'"!:@]/g,
            lower: true,
            strict: true,
            trim: true
          })
        }
        school = _.set({ ...school }, key, value);
        break;
      case 'urn':
        if (!school._id) {
          school.slug = slugify(`${school.slug}-${value}`, {
            replacement: '-',
            remove: /[*+~.()'"!:@]/g,
            lower: true,
            strict: true,
            trim: true
          })
        }
        school = _.set({ ...school }, key, value);
        break;
      case 'slug':
        if (!school._id) {
          school.slug = slugify(`${value}`, {
            replacement: '-',
            remove: /[*+~.()'"!:@]/g,
            lower: true,
            strict: true,
            trim: true
          })
        }
        school = _.set({ ...school }, key, value);
        break
      case "image":
        const impact = { ...school.impact };
        if (!impact?.images) school.impact = { ...impact, images: [] } as IImpact;
        if (value.isDeleted) {
          school.impact?.images?.splice(value.index, 1);
        } else {
          school.impact?.images?.push(value);
        }
        break;
      case "services":
        let services = school.services || [];
        if (value?.selected) {
          if (!services?.find((s) => s === value?.key)) {
            services.push(value.key);
          }
        } else {
          services = services?.filter((s) => s !== value?.key) as string[];
        }
        school.services = services
        break;
      case "services2":
        let services2 = school.services2 || [];
        if (value?.selected) {
          if (!services2?.find((s) => s === value?.key)) {
            services2.push(value.key);
          }
        } else {
          services2 = services2?.filter((s) => s !== value?.key) as string[];
        }
        school.services2 = services2
        break;
      case "coordinates":
        if (!school.address) school.address = {} as IAddress;
        school.address.location = { type: "Point", coordinates: [...value] };
        break;
      case "address":
        school.address = value;
        if (!school._id) {
          if (Regions.filter((r: { key: string; }) => r.key !== 'all').map((s: { key: any; }) => s.key).includes(value.country)) {
            school.country = value.country
          } else {
            school.country = "--"
          }
        }
        break;
      case "activeStage.name":
      case "activeStage.active":
        if (key === "activeStage.name" && value === "all") break;
        const name =
          key === "activeStage.name"
            ? value
            : selectedSchool.activeStage?.name || "Prospect";
        const date = new Date();
        const active =
          key === "activeStage.active"
            ? value
            : selectedSchool.activeStage?.active || false;
        school.activeStage = { name, date, active };
        break;
      case "impact.installed":
        school = _.set({ ...school }, key, value && moment(value).format('YYYY-MM-DD'));
        break;
      case "planDate":
        school = _.set({ ...school }, key, value && moment(value).format('YYYY-MM-DD'));
        break;
      case "address.country":
        if (!school._id) {
          school.address.country = value.toUpperCase()
          if (Regions.filter((r: { key: string; }) => r.key !== 'all').map((s: { key: any; }) => s.key).includes(value)) {
            school.country = value
          } else {
            school.country = "--"
          }
        }
        break;
      default:
        school = _.set({ ...school }, key, value);

        break;
    }
    setSelectedSchool(school);
  };
  const loadScript = useJsApiLoader({
    googleMapsApiKey,
    libraries: mapLibs,
  } as any);

  const onPivotLinkClick = async (props: any) => {
    if (props.key === ".$systems") {
    }
  };

  const handleSave = async () => {
    const errors = await validateSchool(selectedSchool);
    if (Object.keys(errors).length) {
      const tabErrors = getTabErrors(errors, selectedSchool);
      setErrors(errors);
      setTabErrors(tabErrors);
      return;
    }
    if (!school._id) {
      const data: any = await dispatch(createUpdateSchool(selectedSchool))
      setSelectedSchool(data)
    } else {
      onSave(selectedSchool);
    }
  };

  const handleImageUpload = async (file: File | null, type?: string) => {
    let fileContent = {
      file: file,
      fileName: file?.name,
      slug: selectedSchool.slug,
    };

    if (file) {
      const res: any = await uploadImage(fileContent);
      if (res?.data.s3URL) {
        const image = res?.data.s3URL.split("/").pop();
        if (type == 'author') {
          handleOnChangeInput("displaySettings.quote.image", image);
        } else if (type == 'logo') {
          handleOnChangeInput("meta.logo", image);
        } else {
          handleOnChangeInput("image", image);
        }
      }
    }
    setImageUrl(imagePlaceholder);
  };

  // to handle sytem configuration for live school


  const onPlaceChanged = (place: google.maps.places.PlaceResult) => {
    const componentForm: any = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      postal_town: 'long_name',
      country: 'long_name',
      postal_code: 'short_name',
      administrative_area_level_1: 'long_name',
      administrative_area_level_2: 'short_name',
      neighborhood: 'short_name'
    }
    const mapForm: any = {
      street_number: 'street',
      locality: 'locality',
      postal_town: 'city',
      postal_code: 'zipcode',
      neighborhood: 'locality'
    }
    const address: IAddress | any = { ...selectedSchool.address };
    if (place.geometry?.location.lng())
      address.location = {
        type: "Point",
        coordinates: [
          place.geometry?.location.lng(),
          place.geometry?.location.lat(),
        ],
      };
    address.formatted = place.formatted_address || address.formatted;
    const city = place?.address_components?.some(function (x) { return x.types[0] === 'postal_town' })
    const locality = place?.address_components?.some(function (x) { return x.types[0] === 'locality' })
    for (const adrs of place?.address_components || []) { if (adrs.types?.[0] === "country") { address.country = adrs.short_name; console.log(adrs.short_name) } }
    for (const adr of place?.address_components || []) {
      const adrs: any = adr
      const addressType: string = adrs.types[0]
      if (componentForm[addressType]) {
        let val = adrs[componentForm[addressType]]
        if (mapForm[addressType]) {
          address[mapForm[addressType]] = val
        }
        if (!city && address.country === 'IN' && addressType === 'administrative_area_level_2') {
          val = adrs['long_name']
          if (val) {
            address.city = val
          }
        }
        if (addressType === 'administrative_area_level_1') {
          val = adrs['long_name']
          if (val) {
            address.state = val
          }
        }
        if (!city && address.country === 'DE' && addressType === 'locality') {
          val = adrs['long_name']
          if (val) {
            address.city = val
          }
        }
        if (!locality && addressType === 'neighborhood') {
          val = adrs['short_name']
          if (val) {
            address.locality = val
          }
        }
        if (addressType === 'route') {
          val = adrs['short_name']
          if (val && address.country === 'DE') {
            if (address.street) {
              address.street = val + ' ' + address.street
            } else {
              address.street = val
            }
          } else if (val) {
            if (address.street) {
              address.street += address.street + ' ' + val
            } else {
              address.street = val
            }
          }
        }
      }
      // const types = adrs.types;
      // if (types.includes("route") || types.includes("street_address "))
      //   address.street = adrs.long_name;
      // if (types.includes("country")) address.country = adrs.short_name;
      // if (types.includes("administrative_area_level_1"))
      //   address.street = adrs.short_name;
      // if (types.includes("locality")) address.city = adrs.short_name;
      // if (types.includes("postal_code")) address.zipcode = adrs.short_name;
    }
    handleOnChangeInput("address", address);
  };
  // marker drag on map
  const onMarkerDragEnd = (e: google.maps.MapMouseEvent) => {
    handleOnChangeInput("coordinates", [e.latLng.lng(), e.latLng.lat()]);
  };

  const handleOnDeleteImage = async (
    slug: string,
    key: number,
    imgName?: string,
    type?: string
  ) => {
    const confirm = await ConfirmDialog({
      dialogContentProps: {
        title: "Delete Image",
        closeButtonAriaLabel: "Close",
        subText: `Are you want to delete?`,
      },
    });
    if (confirm) {
      const image = imgName ? imgName : (school.impact?.images![key] as string);
      await deleteImage(slug, image);
      if (imgName) {
        handleOnChangeInput(type === 'logo' ? "meta.logo" : "displaySettings.quote.image", null);
      } else {
        handleOnChangeInput("image", { isDeleted: true, index: key });
      }
    }
  };

  useEffect(() => {
    if (systems.length) {
      const impact = setImpact(selectedSchool);
      setSelectedSchool({ ...selectedSchool, impact })
    }
  }, [systems])

  const handlePFSelectClick = async () => {
    const folder: IProjectFolder = {
      folderType: "School",
      region: selectedSchool.country || "GB",
      slug: selectedSchool.slug,
      sfsId: 1,
      name: selectedSchool.name
    };
    if (selectedSchool.activeStage?.name && selectedSchool.activeStage.active) folder.stage = selectedSchool.activeStage?.name;
    const pf = await openProjectFolderModal({ folder })
    if (pf) {
      let school: IEstablishment = _.cloneDeep(selectedSchool);
      school = _.set({ ...school }, "projectFolder", pf);
      setSelectedSchool(school)
    }
  }

  const openProjectFolder = async (data: IProjectFolderLink) => {
    try {
      dispatch(isLoading(true))
      const listItem: any = await getListItemAPi({ driveItemId: data.driveItemId, driveId: data.driveId })
      if (listItem?.webUrl) {
        const win = window.open(listItem?.webUrl, '_blank');
        if (win != null) {
          win.focus();
        }
      }
    } catch (error) {
      console.log(error)
      toast.error('Something happend wrong')
    }
    finally {
      dispatch(isLoading(false))
    }
  }
  const handleCreateProjectFolder = async () => {
    const folder: IProjectFolder = {
      name: selectedSchool.name,
      region: selectedSchool.country,
      folderType: "School",
      slug: selectedSchool.slug,
      sfsId: 1,
    }
    if (selectedSchool.activeStage?.name) folder.stage = selectedSchool.activeStage?.name
    const pf = await OpenCreateProjectFolderModal({ folder })
    if (pf) {
      let school: IEstablishment = _.cloneDeep(selectedSchool);
      school = _.set({ ...school }, "projectFolder", pf);
      setSelectedSchool(school)
    }
  }

  const handleUnlikFolder = async () => {

    const confirm = await ConfirmDialog({
      dialogContentProps: {
        title: "Unlink Project Folder",
        closeButtonAriaLabel: "Close",
        subText: `Are you want to Unlink?`,
      },
    });
    if (confirm) dispatch(unlinkProjectFolder(selectedSchool))
  }

  const handleOnAddOpportunity = async (school: IEstablishment) => {
    let { opportunity, totals = {} }: any = await getOpportunityApi(school.slug, 'School')
    if (!opportunity) opportunity = initOpportunity(school, 'School', totals)
    await OpenOpportunityModal({ params: opportunity })
  }
  const handleOnAddLead = async (school: IEstablishment) => {

    OpenPipedriveLeadModal({ params: { school } })
  }


  return (
    <div className="edit-record">
      <div className="ms-Grid" dir="ltr">
        <Pivot linkSize="large" onLinkClick={(item) => onPivotLinkClick(item)}>
          <PivotItem
            key="general"
            headerText="General"
            onRenderItemLink={onRenderPivoteItemLink(tabErrors.general)}
          >
            <div className="ms-Grid-row">
              <DynamicForm
                fields={generalFields}
                data={selectedSchool}
                onChange={handleOnChangeInput}
                errors={errors}
              />

              <div className="ms-Grid-col ms-lg2" style={{ cursor: "pointer" }}>
                <Label style={{ paddingBottom: '2px' }}>
                  School {projectNameChange['Project']} Folder
                </Label>
                {selectedSchool._id && selectedSchool.projectFolder?.listItemId && (
                  <span style={{ border: '1px solid', padding: '5px 10px' }}>
                    <ActionButton
                      title={`Click to open folder (${selectedSchool.projectFolder?.name}) in browser`}
                      allowDisabledFocus
                      style={{ color: "blue", minWidth: '100%' }}
                      onClick={() => openProjectFolder(selectedSchool.projectFolder!)}
                    >
                      {selectedSchool.projectFolder?.name || 'Open Project Folder'}
                    </ActionButton>
                  </span>
                ) || <span style={{ border: '1px solid', padding: '5px 10px', height: "32px", display: 'block' }}></span>}
              </div>
              <div className="ms-Grid-col ms-lg1" style={{ cursor: "pointer", marginTop: '30px' }}>
                {selectedSchool._id &&
                  <ActionButton
                    title={`Select school ${projectNameChange['Project']} Folder`}
                    iconProps={{ iconName: "folder-link" }}
                    onClick={handlePFSelectClick}
                    style={{ marginTop: '-10px' }}
                  >
                  </ActionButton>}
                {selectedSchool._id && selectedSchool.projectFolder?.driveId && (
                  <>
                    <ActionButton
                      title='Unlink folder'
                      iconProps={{ iconName: "FabricUnsyncFolder", style: { color: 'gray', fontSize: 20 } }}
                      onClick={handleUnlikFolder}
                      style={{ marginTop: '-12px', color: 'black' }}>

                    </ActionButton></>
                )}
                {selectedSchool._id && !selectedSchool.projectFolder?.listItemId && (
                  <ActionButton
                    title={`Create school ${projectNameChange['Project']} Folder`}
                    iconProps={{ iconName: "folder-add" }}
                    onClick={handleCreateProjectFolder}
                    style={{ marginTop: '-10px' }}
                  />
                )}
              </div>
              <div className="ms-Grid-col ms-lg1" style={{ cursor: "pointer" }}>
                <Label style={{ paddingBottom: '2px' }}>
                  Opportunity
                </Label>
                {selectedSchool._id && (
                  <ActionButton iconProps={{ iconName: "Money", style: { color: 'gray', fontSize: 20 } }} title="Add/Edit Opportunity" onClick={() => handleOnAddOpportunity(selectedSchool)} />
                )}
              </div>
              <div className="ms-Grid-col ms-lg1" style={{ cursor: "pointer" }}>
                <Label style={{ paddingBottom: '2px' }}>
                  Add Lead
                </Label>
                {selectedSchool._id && (
                  <ActionButton iconProps={{ iconName: "Money", style: { color: 'gray', fontSize: 20 } }} title="Add/Edit Lead" onClick={() => handleOnAddLead(selectedSchool)} />
                )}
              </div>
            </div>
            <div className="ms-Grid-row margin-top-md">
              <div className="ms-Grid-col ms-lg12">
                <Separator>Meta Informtion</Separator>
              </div>
            </div>
            <div className="ms-Grid-row">
              <DynamicForm
                fields={metaFields}
                data={selectedSchool}
                onChange={handleOnChangeInput}
                errors={errors}
              />
              <ComboBox
                label="Services"
                selectedKey={selectedSchool.services! || null}
                placeholder="Select"
                multiSelect
                allowFreeform={true}
                autoComplete={"on"}
                options={
                  services.map(s => ({ key: s, text: s })) as IComboBoxOption[]
                }
                onChange={(e: any, item: any) => handleOnChangeInput("services", item,)}
                styles={comboBoxStyles}
                className="ms-Grid-col ms-lg2"
              />
              <ComboBox
                label="Services2"
                selectedKey={selectedSchool.services2! || null}
                placeholder="Select"
                multiSelect
                allowFreeform={true}
                autoComplete={"on"}
                options={
                  services2.filter(s => s.type.trim().toLowerCase() === 'school') as unknown as IComboBoxOption[]
                }
                onChange={(e: any, item: any) => handleOnChangeInput("services2", item,)}
                styles={comboBoxStyles}
                className="ms-Grid-col ms-lg2" />
            </div>
            <div className="ms-Grid-row">
              {metaData.map(m => {
                return <MetaSelection key={m.key} onUpdate={handleOnChangeInput} school={selectedSchool} type={m.key} label={m.label} placeholder={m.placeholder} className={m.className} />
              })}
            </div>
          </PivotItem>
          <PivotItem
            key="location"
            headerText="Location/Address"
            onRenderItemLink={onRenderPivoteItemLink(tabErrors.location)}
          >
            <>
              <div className="ms-Grid-row margin-top-lg">
                {loadScript.isLoaded && (
                  <MapSearch
                    school={selectedSchool}
                    onMarkerDragEnd={onMarkerDragEnd}
                    onPlaceChanged={onPlaceChanged}
                  />
                )}
              </div>
              <div className="ms-Grid-row">
                <DynamicForm
                  fields={locationFields}
                  data={selectedSchool}
                  onChange={handleOnChangeInput}
                  errors={errors}
                />
              </div>
              <div className="ms-Grid-row">
                <DynamicForm
                  fields={addressFileds}
                  data={selectedSchool}
                  onChange={handleOnChangeInput}
                  errors={errors}
                />
              </div>
            </>
          </PivotItem>
          <PivotItem
            key="display"
            headerText="Display Settings"
            onRenderItemLink={onRenderPivoteItemLink(tabErrors.display)}
          >
            <div className="ms-Grid-row">
              <DynamicForm
                fields={displayDataFields}
                data={selectedSchool}
                onChange={handleOnChangeInput}
                errors={errors}
              />
            </div>
            <div className="ms-Grid-row">
              <div
                className="ms-Grid-col ms-lg2"
                style={{ height: "200px", width: "200px" }}
              >
                <FileUpload
                  name="+ Author Image"
                  url={imageUrl}
                  width="100%"
                  height="100%"
                  fileType="image/*"
                  onChangeCallback={(file: File | null) =>
                    handleImageUpload(file, "author")
                  }
                />
              </div>
              {selectedSchool.displaySettings?.quote?.image && (
                <div
                  className="ms-Grid-col ms-lg2 margin-top-md image-area"
                  style={{ height: "200px", width: "200px" }}
                >
                  <img
                    src={`${awsUrlHost}/schools/${selectedSchool.slug}/live/${selectedSchool.displaySettings?.quote?.image}`}
                    alt={"NoImage"}
                  />
                  <button
                    className="remove-image"
                    onClick={() =>
                      handleOnDeleteImage(
                        selectedSchool.slug,
                        0,
                        selectedSchool.displaySettings?.quote?.image
                      )
                    }
                    style={{ display: "inline" }}
                  >
                    &#215;
                  </button>
                </div>
              )}
            </div>
            <div className="ms-Grid-row">
              <div
                className="ms-Grid-col ms-lg2 margin-top-md"
                style={{ height: "150px", width: "150px" }}
              >
                <FileUpload
                  name="+ Logo Image"
                  url={imageUrl}
                  width="100%"
                  height="100%"
                  fileType="image/*"
                  onChangeCallback={(file: File | null) =>
                    handleImageUpload(file, "logo")
                  }
                />
              </div>
              {selectedSchool.meta?.logo && (
                <div
                  className="ms-Grid-col ms-lg2 margin-top-lg image-area"
                  style={{ height: "150px", width: "150px" }}
                >
                  <img
                    src={`${awsUrlHost}/schools/${selectedSchool.slug}/live/${selectedSchool.meta?.logo}`}
                    alt={"NoImage"}
                  />
                  <button
                    className="remove-image"
                    onClick={() =>
                      handleOnDeleteImage(
                        selectedSchool.slug,
                        0,
                        selectedSchool.meta?.logo,
                        'logo'
                      )
                    }
                    style={{ display: "inline" }}
                  >
                    &#215;
                  </button>
                </div>
              )}
            </div>
          </PivotItem>
          {selectedSchool.live && (
            <PivotItem
              key="live"
              headerText="Live Data"
              onRenderItemLink={onRenderPivoteItemLink(tabErrors.live)}
            >
              <div className="ms-Grid-row">
                <DynamicForm
                  fields={liveDataFields}
                  data={selectedSchool}
                  onChange={handleOnChangeInput}
                  errors={errors}
                />
              </div>
              <div className="ms-Grid-row">
                <div
                  className="ms-Grid-col ms-lg2"
                  style={{ height: "200px", width: "200px" }}
                >
                  <FileUpload
                    name="+ Add Image"
                    url={imageUrl}
                    width="100%"
                    height="100%"
                    fileType="image/*"
                    onChangeCallback={handleImageUpload}
                    isDirectUpload={true}
                  />
                </div>
                {selectedSchool.impact?.images &&
                  selectedSchool.impact?.images!.map(
                    (image: any, i: number) => {
                      return (
                        <div
                          className="ms-Grid-col ms-lg2 margin-top-md image-area"
                          style={{ height: "200px", width: "200px" }}
                        >
                          <img
                            key={i}
                            src={`${awsUrlHost}/schools/${selectedSchool.slug}/live/${image}`}
                            alt={"NoImage"}
                          />
                          <button
                            className="remove-image"
                            onClick={() =>
                              handleOnDeleteImage(selectedSchool.slug, i)
                            }
                            style={{ display: "inline" }}
                          >
                            &#215;
                          </button>
                        </div>
                      );
                    }
                  )}
              </div>
            </PivotItem>
          )}
          {selectedSchool.live && (
            <PivotItem key="systems" headerText="Systems">
              <SchoolSystems school={selectedSchool} />
            </PivotItem>
          )}
          <PivotItem key="tasks" headerText="Tasks">
            <SchoolTasks school={selectedSchool} />
          </PivotItem>
        </Pivot>
      </div>
      <Sticky stickyPosition={StickyPositionType.Footer}>
        <Stack horizontal horizontalAlign="center">
          <Stack.Item>
            <PrimaryButton
              text="Save"
              onClick={handleSave}
              className="btn-primary"
            />
            {!school._id &&
              <DefaultButton
                text="Close"
                onClick={onCancel}
                style={{ marginRight: 15 }}
              />
            }
            <DefaultButton onClick={onCancel} text="Cancel" />
          </Stack.Item>
        </Stack>
      </Sticky>
    </div>
  );
};

interface EditSchoolProps {
  onCancel: () => void;
  onSave: (system: IEstablishment) => void;
  school: IEstablishment;
  onFetchSystem: () => any
}

export default EditSchoolDialog;
